import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import TextLabel from '../../../elements/text-label/text-label'
import Loader, { TYPE_INLINE } from '../../../elements/loader/loader'
import _t from 's3p-js-lib/src/translate'
import { checkIsOnline } from 's3p-js-lib/src/redux/actions/api/offline'

const checkBackOnlineInterval = 5

class Poller extends React.Component {
  static propTypes = {
    isLoadingPing: PropTypes.bool,
    checkIsOnline: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this._timer = this._timer.bind(this)
    this._poll = this._poll.bind(this)

    this.state = {
      checkInSeconds: checkBackOnlineInterval
    }
  }

  _poll () {
    this.props.checkIsOnline().then(success => {
      if (!success) {
        this.setState({
          checkInSeconds: checkBackOnlineInterval
        })
      }
    })
  }

  _timer () {
    const nextTick = this.state.checkInSeconds - 1
    if (nextTick === 0) {
      this._poll()
    } else {
      this.setState({
        checkInSeconds: nextTick
      })
    }
  }

  componentDidMount () {
    this.timer = setInterval(this._timer, 1000)
  }

  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render () {
    return this.props.isLoadingPing
      ? <Loader type={TYPE_INLINE} />
      : <TextLabel text={_t.message('offline.retry-ping', {seconds: this.state.checkInSeconds})} />
  }
}

const mapStateToProps = state => ({
  isLoadingPing: state.api.loading.ping
})

const mapDispatchToProps = {
  checkIsOnline
}

export default connect(mapStateToProps, mapDispatchToProps)(Poller)
